html {
  height: 100%;
}
.body-style {
  margin: 0;
  font-family: 'TTNormsProRegular', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  position: relative;
  height: unset;
  background-color: #fff !important;
}
@font-face {
  font-family: 'TTNormsProRegular';
  src: url('../src/fonts/TT Norms Pro Regular.otf') format('opentype');
}
@font-face {
  font-family: 'TTNormsProItalic';
  src: url('../src/fonts/TT Norms Pro Italic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'TTNormsProMedium';
  src: url('../src/fonts/TT Norms Pro Medium.otf') format('opentype');

  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'TTNormsProBold';
  src: url('../src/fonts/TT Norms Pro Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'TTNormsProBoldItalic';
  src: url('../src/fonts/TT Norms Pro Bold Italic.otf') format('opentype');
  font-weight: bold;
  font-style: Italic;
}
@font-face {
  font-family: 'TTNormsProExtraBlack';
  src: url('../src/fonts/TT Norms Pro ExtraBlack.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'TTNormsProBlack';
  src: url('../src/fonts/TT Norms Pro Black.otf') format('opentype');

  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'TTNormsProBlackItalic';
  src: url('../src/fonts/TT Norms Pro ExtraBold Italic.otf') format('opentype');
  font-weight: bold;
  font-style: Italic;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: 6px !important;
}

.body-style:after {
  content: '';
  display: block;
  /* height: 50px; */
}
.vouched-body-style {
  margin: 0;
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  position: relative;
  height: unset;
  background-color: #fff !important;
}

.handoff-title {
  margin-bottom: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* .checkbox-label {
  cursor: pointer;
  margin-top: 5px;
}

.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  margin: 12px;
}

.checkbox-label .checkbox-custom {
  display: inline-block;
  height: 13px;
  width: 13px;
  background-color: #fff;
  border-radius: 3px;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  border: 2px solid rgba(0, 0, 0, 0.54);
}

.checkbox-label input:focus ~ .checkbox-custom {
  background-color: #ffffff;
  border-radius: 3px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid #f5821f;
}

.checkbox-label input:checked ~ .checkbox-custom {
  background-color: #ffffff;
  border-radius: 3px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid rgba(0, 0, 0, 0.54);
}

.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: '';
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 5px;
  border: solid #009bff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity: 1;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
}

.checkbox-label input:checked ~ .checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 8px;
  top: -12px;
  width: 7px;
  height: 21px;
  border: solid #f5821f;
  border-width: 0 3px 3px 0;
  background-color: transparent;
  border-radius: 0;
} */

/* 
.checkbox-label .checkbox-custom::before {
  position: absolute;
  content: '';
  left: 10px;
  top: 10px;
  width: 0px;
  height: 0px;
  border-radius: 5px;
  border: 2px solid #ffffff;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.checkbox-label input:checked ~ .checkbox-custom::before {
  left: -3px;
  top: -3px;
  width: 18px;
  height: 18px;
  border-radius: 5px;
  -webkit-transform: scale(3);
  -ms-transform: scale(3);
  transform: scale(3);
  opacity: 0;
  z-index: 999;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
} */

/* --------------------here ------------------------- */

.checkbox_label {
  cursor: pointer;
  margin-top: 5px;
  height: 25px;
  /* display: inline-block; */
}

.checkbox_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  margin: 12px;
}

.checkbox_label .checkbox_custom {
  display: inline-block;
  height: 17px;
  width: 17px;
  background-color: rgb(255, 255, 255);
  border-radius: 3px;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  /* border: 2px solid #661c69; */
  border: 1px solid #661c69;
}

.checkbox_label input:focus ~ .checkbox_custom {
  background-color: #ffffff;
  border-radius: 3px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  outline: 2px solid #4c134e;
  outline-offset: 2px;
}

.checkbox_label input:disabled ~ .checkbox_custom {
  border-color: #d4d0d4 !important;
  /* background: #a473a6 !important; */
}

.checkbox_label input:checked ~ .checkbox_custom {
  background-color: #661c69;
  border-radius: 3px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid #661c69;
  height: 17px;
  width: 17px;
}

.checkbox_label .checkbox_custom::after {
  position: absolute;
  content: '';
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 5px;
  border: solid #009bff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity: 1;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
}

.checkbox_label input:checked ~ .checkbox_custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 4px;
  top: 2px;
  width: 6px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}

/* For Ripple Effect */

.checkbox_label .checkbox_custom::before {
  position: absolute;
  content: '';
  left: 10px;
  top: 10px;
  width: 0px;
  height: 0px;
  border-radius: 5px;
  border: 2px solid #661c69;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.checkbox_label input:checked ~ .checkbox_custom::before {
  left: -3px;
  top: -3px;
  width: 18px;
  height: 18px;
  border-radius: 5px;
  -webkit-transform: scale(3);
  -ms-transform: scale(3);
  transform: scale(3);
  opacity: 0;
  z-index: 999;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}
.checkbox_label_style {
  margin-left: 2px;
  text-align: left;
  font: normal normal 300 16px/18px Arial;
  letter-spacing: 0.11px;
  color: blue;
  opacity: 1;
}

.checkbox_content_position {
  display: block;
  position: relative;
  top: -20px;
  left: 30px;
  width: fit-content;
  /* margin-right: 30px; */
  /* [theme.breakpoints.down(600)]: {
      top: -18px,
      left: 27px,
    }, */
}
/* ---------------------------end here ------------------------ */
.MuiStep-horizontal {
  padding: 0 !important;
}

.custom-connector {
  width: 100%;
  height: 1px;
  background: #5d5a5a;
  position: absolute;
  top: 20px;
}

@media screen and (max-width: 650px) {
  .custom-connector {
    top: 18px;
  }
}

.MuiStepLabel-label.MuiStepLabel-completed > div.custom-connector {
  background: #5d5a5a !important;
}

.MuiStepLabel-label.MuiStepLabel-active > div.custom-connector {
  background: #5d5a5a !important;
}

.MuiStepLabel-label {
  color: #555858 !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
  color: #555858 !important;
}

.MuiStepConnector-lineHorizontal {
  display: none !important;
}

.personal_mo_number fieldset.MuiOutlinedInput-notchedOutline {
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
  display: none;
}

.dob-select fieldset.MuiOutlinedInput-notchedOutline {
  /* background: rgba(85, 88, 88, 0.02) 0% 0% no-repeat padding-box;
  border: 0.51px solid #555858;
  border-right: 0;
  opacity: 1;
  border-left: 0;
  border-radius: 0; */
  box-sizing: 'border-box';
  background: '#FFFFFF';
  border: '1px solid FD0000';
  border-radius: '6px';
  height: '48px';
  /* //  */
  max-width: '107px';
}

.dob-select-day fieldset.MuiOutlinedInput-notchedOutline {
  box-sizing: 'border-box';
  background: '#FFFFFF';
  border: '1px solid FD0000';
  border-radius: '6px';
  height: '48px';
  /* //  */
  max-width: '91px';

  /* border-radius: 3px 0 0 3px; */
  /* background: rgba(85, 88, 88, 0.02) 0% 0% no-repeat padding-box; */
  /* border: 0.51px solid #555858;
  border-right: 0;
  opacity: 1; */
}

.dob-select-datepicker {
  justify-content: flex-end;
  align-items: center;
  /* background: rgba(85, 88, 88, 0.02) 0% 0% no-repeat padding-box !important; */
  background: rgba(85, 88, 88, 0.04) 0% 0% no-repeat padding-box;
  border: 0.51px solid #555858;
  border-radius: 0 3px 3px 0;
  border-left: 0;
  opacity: 1;
}

.dob-select-datepicker .MuiGrid-item {
  margin-right: 14px;
}

.dob-select-empty {
  border: 0.51px solid #555858;
  border-radius: 0 3px 3px 0;
  border-left: 0;
  opacity: 1;
}

.MuiSelect-select option {
  text-align: left;
  font: normal normal normal 14px/17px Arial, sans-serif;
  letter-spacing: 0.1px;
  color: #661c69;
  opacity: 1;
}

/* @media screen and (max-width: 1279px) { */

.grid-right-side {
  margin-left: 6%;
  max-width: calc(66.66% + 2%);
  flex-basis: calc(66.66% + 2%);
}

/* } */

/* @media screen and (min-width: 1550px){
  .grid-right-side{
    flex-basis: 67.666%;
  }
} */

@media screen and (min-width: 1500px) {
  .grid-right-side {
    margin-left: 2%;
  }
}

@media screen and (min-width: 1400px) {
  .grid-right-side {
    margin-left: 3%;
    max-width: calc(66.66% + 5%);
    flex-basis: calc(66.66% + 5%);
  }
}

@media screen and (max-width: 959px) {
  .grid-right-side {
    margin-left: 0%;
    margin-top: 30px;
    max-width: 100%;
    flex-basis: 100%;
  }
}

@media screen and (max-width: 650px) {
  .grid-right-side {
    margin-top: 0px;
  }
}

.date_dob .MuiSelect-outlined.MuiSelect-outlined {
  padding-right: 20px;
  padding-left: 15px;
}

.month_dob .MuiSelect-outlined.MuiSelect-outlined {
  padding-right: 20px;
  padding-left: 15px;
}

.formData :nth-child(2) div:nth-child(2) {
  width: 50%;
}

@media screen and (max-width: 320px) {
  .MuiSelect-iconOutlined {
    right: 0;
  }
}

/* .vouched .handoff {
  width: 220px !important;
  margin-right: 10px;
}

.vouched canvas {
  width: 205px !important;
  height: 205px !important;
} */

#screen1-back-button {
  background-color: #661c69;
  color: #ffffff;
  width: 86px;
  height: 45px;
  font: normal normal normal 18px/21px Arial, sans-serif;
  text-transform: none;
  border: 0.5px solid #661c69;
  border-radius: 7px;
  display: none;
  float: right;
}

#screen1-back-button:hover {
  background-color: #661c69;
}

.note-text {
  font-size: 28px !important;
}

.vouched {
  background-color: #fff;
  height: 100%;
  position: unset;
  box-sizing: border-box;
  /* margin: 15px 0; */
}

.vouched-description {
  margin: 0 20px;
  width: unset !important;
  max-width: 700px;
}

#vouched_handoffLink {
  font-weight: 600;
}

.vouched_start_not-selected {
  fill: #707070;
}

/* #vouchedVerboseIDDiv{
  background-color: #f5821f !important;
} */

.start_selected {
  fill: #661c69;
}

#vouched_textArea {
  color: #661c69;
  font-weight: 600;
}

#vouched_surveyButton {
  background-color: #661c69 !important;
  color: white;
}

#vouched_surveyButton.disabled {
  color: rgba(255, 255, 255, 0.6) !important;
}

/* #dob_day{
  padding-left: 25px;
} */

.dob-input-adjust .MuiSelect-iconOutlined {
  left: 35px;
}

/* #idScreenButtons {
  position: unset
} */

#idScreenButtons .vouched_buttonText {
  border-radius: 27px !important;
}

.product-page input[type='radio'] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #661c99;
  border-radius: 50%;
  /* outline:none; */
}

.product-page input[type='radio']:before {
  content: '';
  display: block;
  width: 52%;
  height: 55%;
  margin: 20% 23%;
  border-radius: 50%;
}

.product-page .MuiRadio-colorSecondary.Mui-checked {
  color: #661c99;
}
.ssnEyeIcon:focus,
.suriSearchIcon:focus {
  border: 2px solid #000;
  border-radius: 0;
  padding: 0;
  margin-right: -2px;
}
.ssnEyeIcon:focus {
  margin-right: -2px;
}
.suriSearchIcon:focus {
  margin-right: 10px;
}
#calendarBtn > button:focus {
  border: 2px solid #000;
  border-radius: 0;
  padding: 0;
  margin-left: -22px;
}

#close_id:focus {
  border: 2px solid #000;
  border-radius: 0;
  height: 30px;
  width: 30px;
  margin: 18px;
}

/* .product-page .MuiSvgIcon-root{
  width: 0.8em;
  height: 0.8em;
} */

.product-page input[type='radio']:checked:before {
  background: #661c99;
  margin: 24%;
}

.anchor-style a {
  color: #661c69 !important;
  font-weight: bold !important;
  text-decoration: none;
  font-family: Arial;
}

.anchor-style a:focus,
.vouched a:focus,
.vouched a:hover {
  text-decoration: underline;
}
.vouched button:focus {
  outline: 2px solid #661c69;
  outline-offset: 2px;
}

.start-step-0 {
  width: 5px;
  height: 5px;
  position: absolute;
  border: 1px solid #5d5a5a;
  border-radius: 50%;
  top: 17px;
  left: -7px;
}

.end-step-2 {
  width: 5px;
  height: 5px;
  position: absolute;
  border: 1px solid #5d5a5a;
  border-radius: 50%;
  top: 17px;
  right: -7px;
}

@media screen and (max-width: 650px) {
  .start-step-0 {
    top: 15px;
  }
  .end-step-2 {
    top: 15px;
  }
}

.modal-container {
  display: flex;
  align-items: center;
}

.modal-grid-style {
  outline: 0;
  justify-content: center;
  margin: 0 auto;
  width: 75%;
  border-radius: 10px;
  padding: 20px;
}

@media screen and (max-width: 800px) {
  .modal-grid-style {
    width: 90%;
  }
}

.MuiInputLabel-asterisk {
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 22px;
  font-family: Arial, sans-serif;
  letter-spacing: 0.15px;
  color: #e37617;
  opacity: 1;
  position: absolute;
}

@media screen and (max-width: 780px) {
  #subAriaDesc .MuiInputLabel-asterisk {
    height: 38px;
    display: block;
    float: left;
    margin-right: 4px;
    position: relative;
  }
}

@media screen and (min-width: 780px) {
  #subAriaDesc .MuiInputLabel-asterisk {
    position: relative;
  }
}

#vouched_handoffLinkProps {
  color: rgb(102, 28, 105);
  font-weight: bold;
  text-decoration: underline;
}

@media screen and (min-width: 1460px) {
  .custom-container {
    width: 1360px;
  }
}

.address-container {
  padding: 50px 50px 50px 0;
}

@media screen and (max-width: 960px) {
  .address-container {
    padding: 25px;
  }
}

.stepper {
  position: absolute;
  top: 19px;
  width: 90%;
  margin-left: 40px;
}

.stepper::before {
  width: 5px;
  height: 5px;
  border: 1px solid #661c69;
  position: absolute;
  top: -3px;
  left: 0px;
  border-radius: 50%;
  background: #fff;
  z-index: 1;
}

.stepper::after {
  width: 5px;
  height: 5px;
  border: 1px solid #5d5a5a;
  position: absolute;
  top: -3px;
  right: 0px;
  border-radius: 50%;
  background: #fff;
}

.MuiLinearProgress-root {
  height: 2px !important;
  background-color: #d0d0d0 !important;
  top: 1px;
}

/* .MuiLinearProgress-barColorPrimary{
  background-color: #E37617 !important;
  top: -1px !important;
  height: 3px !important;
} */

.active-progress-bar {
  position: absolute;
  top: -0.5px;
  height: 4px;
  background-color: #661c69;
}
.forYouBtn:focus {
  border: 2px solid #000;
}

.consent_links {
  margin-bottom: 10px;
  width: fit-content;
  display: flex;
  align-items: center;
}
.consent-links:focus {
  text-decoration: underline;
}
.MuiSnackbarContent-message {
  width: 100%;

  display: flex;

  align-items: flex-start;
}

.loader-style {
  position: fixed;
  top: 0;
  bottom: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 99;
}
.loader-saveApp-style {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 99;
}
.consent-loader {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 25px;
}
.consent-loader-style {
  display: flex;
  justify-content: center;
  width: 100%;
}
.p-relative {
  position: relative;
}
.drop-file-input:hover,
.drop-file-input.dragover {
  opacity: 0.6;
}
.stepperLayoutStyles {
  width: 100%;
  /* margin: 25px 0; */
}
/* @media screen and (max-width: 600px) {
  .stepperLayoutStyles {
    margin: 25px 0;
  }
}
@media screen and (max-width: 650px) {
  .stepperLayoutStyles {
    margin: 25px;
    margin-bottom: 0px;
  }
} */
.custom-container-new {
  padding: 20px;
  background: #f8f8f8;
}
.secondary-container-new {
  padding: 25px 35px;
  background: #ffffff;
}
@media screen and (max-width: 600px) {
  .custom-container-new {
    padding: 10px;
    background: #e3e2e2;
  }
  .secondary-container-new {
    padding: 18px 20px;
    background: #ffffff;
  }
}

.MuiTypography-root.MuiTypography-h5.PrivatePickersMonth-root.Mui-selected {
  color: rgb(255, 255, 255);
  background-color: rgb(102, 28, 105);
}

.MuiTypography-root.MuiTypography-h5.PrivatePickersMonth-root {
  flex: 1 0 33.33%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  /* color: unset; */
  /* background-color: transparent; */
  border: 0px;
  outline: 0px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.75;
  letter-spacing: 0.00938em;
  height: 36px;
  border-radius: 18px;
  cursor: pointer;
}
.smallIconFocus:focus {
  border: 1px solid #000;
  border-radius: 0;
}
#skipToMain {
  left: -999px;
  position: absolute;
  top: auto;
  text-decoration: underline;
  z-index: -999;
  white-space: nowrap;
}

#skipToMain:focus,
#skipToMain:active {
  color: #000;
  background-color: #d1d1d1;
  left: auto;
  top: auto;
  height: auto;
  overflow: auto;
  border: 4px solid #ccc;
  text-align: center;
  font-size: 0.8em;
  z-index: 999;
}

.deposite-left .lists p {
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  font-family: 'Arial';
}
.deposite-left .list {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #444444;
  font-family: 'Arial';
}
.text-container {
  padding: 5px 20px;
  border-radius: 15px;
  background-color: #f3f3f3;
}

.css-cveggr-MuiListItemIcon-root {
  min-width: 24px !important;
  color: #000000 !important;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  color: #661c69 !important;
}

.consent-border {
  border-top: 1px solid #e6e6e6;
  margin-bottom: 25px;
}
.consent-heading {
  font-family: Arial;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  text-align: left;
}

.consent_links_terms {
  color: #661c69 !important;
  font-weight: bold !important;
  text-decoration: none;
  font-family: Arial;
}
.consent_links_terms:disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}
.consent_links_sign {
  background-color: #661c69 !important;
  color: #ffffff;
  font-weight: bold !important;
  text-decoration: none;
  font-family: Arial;
  padding: 5px 25px;
  border-radius: 25px;
}

.download_all {
  margin-left: -0px !important;
}
.check_step {
  display: inline-block;
}

.icon {
  vertical-align: middle;
  display: inline-block;
}
.icon_border {
  border-left: 5px solid #ffffff !important;
  border-right: 5px solid #ffffff !important;
}
