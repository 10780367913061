.label_title {
  color: #444444;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.1px;
  text-align: left;
  line-height: 18px;
  margin-bottom: 10px;
}

/* .label_title span {
  color: #fd0000;
} */

.outlined_inputs {
  background: #ffffff;
  text-align: left;
  opacity: 1;
  box-sizing: border-box;
  height: 48px;
  font: normal normal 300 14px/16px Arial, Sans-serif;
  border: 1px solid #e4e9f2;
  letter-spacing: 0.1px;
}

.outlined_inputs input {
  padding: 12.5px 16px;
  color: #000000;
  height: 48px;
}

.outlined_inputs fieldset {
  border-radius: 6px;
  border: 1px solid #e4e9f2;
  border: 0;
}
/* .outlined_inputs_transaction {
  background: #ffffff;
  text-align: left;
  opacity: 1;
  box-sizing: border-box;
  height: 48px;
  font: normal normal 300 14px/16px Arial, Sans-serif;
  letter-spacing: 0.1px;
}

.outlined_inputs_transaction input {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 0px;
  padding-left: 16px;
}
.outlined_inputs_transaction fieldset {
  border-radius: 6px;
  /* border: 1px solid #e4e9f2; */
/* border: 0;
} */

/* .outlined_inputs_error fieldset {
  border-radius: 6px;
  border: 1.6px solid #fd0000;
} */

.outlined_inputs ::-webkit-input-placeholder,
.outlined_inputs :-ms-input-placeholder,
.outlined_inputs ::-moz-placeholder {
  color: #444444;
  opacity: 1;
}

.outlined_inputs_transaction ::-webkit-input-placeholder,
.outlined_inputs_transaction ::-moz-placeholder,
.error {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #fd0000;
  margin-top: 2px;
}
.disableError {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #bdbdbd;
  margin-top: 2px;
}

.dobIconContainer {
  display: flex;
  justify-content: end;
  position: relative;
  top: -23px;
  right: 15px;
}

.dobContainer {
  border-radius: 6px;
  border: 1px solid #e4e9f2 !important;
  width: 100%;
  display: flex;
  align-items: center;
}

/* .dobPick {
    margin-right: 15px;
  } */
/* .datePickerBox :nth-child(2) {
    display: flex;
    justify-content: end;
    position: relative;
    top: -23px;
    right: 15px;
  } */

@media only screen and (max-width: 600px) {
  .label_title {
    margin-bottom: 5px;
    color: #444444;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    letter-spacing: 0.1px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1280px) {
  .outlined_inputs_transaction input {
    padding-left: 8px;
  }
}
